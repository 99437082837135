<template>
  <div class="card">
    <div class="card-header header-elements-inline bg-success-400">
      <h6 class="card-title"> {{fabric.code}} # {{fabric.name}} : Processes List</h6>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="table-responsive ">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Process</th>
              <th>Design</th>
              <th>Color</th>
              <th>Quality</th>
              <th>Style</th>
              <th>Size</th>
              <!--<th>Qty</th>-->
              <th>Weight</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody v-if="processedfabs" v-for="(fabric,index) in processedfabs" >
            <tr>
              <td style="padding: 0px;">
                <select class="form-control" v-if="fabric" v-model="fabric.process.code" >
                  <option v-for="process in processes" v-bind:value="process.code">
                    {{ process.name }}
                  </option>
                </select>
              </td>
              <td style="padding: 0px;">
                <select class="form-control" v-if="fabric" v-model="fabric.design.code" >
                  <option value="1">-</option>
                  <option v-for="design in designs" v-bind:value="design.code">
                    {{ design.name }}
                  </option>
                </select>
              </td>
              <td style="padding: 0px;">
                <select class="form-control" v-if="fabric" v-model="fabric.color.code" >
                  <option value="1">-</option>
                  <option v-for="color in colors" v-bind:value="color.code">
                    {{ color.name }}
                  </option>
                </select>
              </td>
              <td style="padding: 0px;">

                <select class="form-control" v-if="fabric" v-model="fabric.quality.code" >
                  <option value="1">-</option>
                  <option v-for="quality in qualities" v-bind:value="quality.code">
                    {{ quality.name }}
                  </option>
                </select>
              </td>
              <td style="padding: 0px;">

                <select class="form-control" v-if="fabric" v-model="fabric.style.code" >
                  <option value="1">-</option>
                  <option v-for="style in styles" v-bind:value="style.code">
                    {{ style.name }}
                  </option>
                </select>
              </td>
              <td style="padding: 0px;">
                <select class="form-control" v-if="fabric" v-model="fabric.size.code" >
                  <option v-for="size in sizes" v-bind:value="size.code">
                    {{ size.name }}
                  </option>
                </select>
              </td>
              <!--<td>-->
                <!--<input type="number" class="form-control text-right" placeholder="Qty" min="0"  v-if="fabric" v-model="fabric.qty" >-->
              <!--</td>-->
              <td style="padding: 0px;">
                <input type="number" class="form-control text-right" placeholder="Weight in Kgs" min="0"  v-if="fabric" v-model="fabric.weight" >
              </td>
              <td style="padding: 0px;width: 30px;text-align: center;">
                <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <td style="padding: 0px;">
              <button type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                <i class="icon-plus3"></i>
              </button>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <!--<td></td>-->

          </tfoot>
        </table>
      </div>
    </div>
    <div class="card-footer" style="padding-top: 0px;padding-bottom: 0px;">
      <div class="text-right" >
        <button type="button" class="btn btn-outline-primary" @click="saveDocument" >Update<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'FabricProcessForm',
    data () {
      return {
        processedfabs: [],
        fabric: JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}'),
        processes: [],
        designs: [],
        colors:[],
        qualities: [],
        styles:[],
        sizes: []
      }
    },
    props: {
      myfabric: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}')
      }
    },
    beforeMount () {
      //if(this.myfabric.code > 1){
        this.fabric = this.myfabric; // save props data to itself's data and deal with tms
      //}
    },
    component: {

    },
    created () {
      //this.$data.fabric = JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}')
    },
    mounted () {
      let self = this;
      $('.form-control-select2').select2()
      self.loadAll();

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      let code = this.$data.fabric.code;

      self.$data.processedfabs = [];
      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/fabric/processlist/${code}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.accepted) {
          if(resp.data != null){
            self.$data.processedfabs = resp.data;
          }
        } else {
          swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });

    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeProcessModal();
      },
      clear(){
        this.$data.fabric = JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}');
      },
      addRow(){

        let fab = JSON.parse(JSON.stringify(this.fabric));
        fab.process.code = 0;
        fab.code = 0;
        this.$data.processedfabs.push (fab);
        //this.$data.processedfabs.push (JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}'));

      },
      removeRow(idx){
        if (idx > -1) {
          this.$data.processedfabs.splice(idx, 1);
        }
      },
      loadAll(){
        this.loadProcesses();
        this.loadQualities();
        this.loadColors();
        this.loadDesigns();
        this.loadStyles();
        this.loadSizes();
      },
      loadProcesses(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/after/305/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.processes = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadColors(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/color/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.colors = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadDesigns(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/design/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.designs = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadQualities(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/quality/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.qualities = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadStyles(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/style/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.styles = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadSizes(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/size/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.sizes = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      saveDocument(){
        let self = this;


        if(self.$data.fabric.code < 1){
          alert("Invalid Fabric");
          return
        }else if (self.$data.processedfabs.length < 1){
          alert("Invalid List");
          return
        }

        self.$data.processedfabs.forEach(function (fab) {
          fab.parent = self.$data.fabric.code;
          fab.article.code = self.$data.fabric.article.code;
          fab.weight =  parseFloat(fab.weight);
          fab.qty =  parseFloat(fab.qty);
          fab.reed = self.$data.fabric.reed;
          fab.pick = self.$data.fabric.pick;

          fab.design.code = parseInt(fab.design.code);
          fab.color.code = parseInt(fab.color.code);
          fab.quality.code = parseInt(fab.quality.code);
          fab.style.code = parseInt(fab.style.code);

        });

        const requestOptions = {
          method:  'PUT',
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.processedfabs)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/fabric/processlist/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.fabric = JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}');
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
