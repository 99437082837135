<template>
  <div class="card">
    <div class="card-header header-elements-inline bg-success-400">
      <h6 class="card-title">{{fabric.name}} : Yarn Requirements</h6>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <ul class="nav nav-tabs nav-tabs-highlight">
        <li class="nav-item"><a href="#highlighted-tab1" class="nav-link legitRipple active show" data-toggle="tab">Warp</a></li>
        <li class="nav-item"><a href="#highlighted-tab2" class="nav-link legitRipple" data-toggle="tab">Weft</a></li>
      </ul>

      <div class="tab-content">

        <div class="tab-pane fade active show" id="highlighted-tab1" style="background-color: whitesmoke">
          <div class="table-responsive ">
            <table class="table">
              <thead>
                <tr>
                  <th>Count</th>
                  <th>Design</th>
                  <th>Color</th>
                  <th>Weight</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody >
                <tr v-model="fabric" v-for="(detail,index) in fabric.warp" >
                <td style="padding: 0px">
                  <select class="form-control" v-if="detail" v-model="detail.yarn.count.code" >
                    <option v-for="count in counts" v-bind:value="count.code">
                      {{ count.name }}
                    </option>
                  </select>
                </td>
                <td style="padding: 0px">
                  <select class="form-control" v-if="detail" v-model="detail.yarn.design.code" >
                    <option value="1">-</option>
                    <option v-for="design in designs" v-bind:value="design.code">
                      {{ design.name }}
                    </option>
                  </select>
                </td>
                <td style="padding: 0px">
                  <select class="form-control" v-if="detail" v-model="detail.yarn.color.code" >
                    <option value="1">-</option>
                    <option v-for="color in colors" v-bind:value="color.code">
                      {{ color.name }}
                    </option>
                  </select>
                </td>
                <td style="padding: 0px">
                  <input type="number" class="form-control text-right" placeholder="Weight in Kgs" min="0"  v-if="detail" v-model="detail.weight" >
                </td>
                <td style="padding: 0px;text-align: center;width: 30px;">
                  <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeWarpRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <td style="padding: 0px;">
                <button type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addWarpRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              </tfoot>
            </table>
          </div>
        </div>

        <div class="tab-pane fade" id="highlighted-tab2" style="background-color: lightgrey">
          <div class="table-responsive ">
            <table class="table">
              <thead>
              <tr>
                <th>Count</th>
                <th>Design</th>
                <th>Color</th>
                <th>Weight</th>
                <th>#</th>
              </tr>
              </thead>
              <tbody  >
                <tr v-model="fabric" v-for="(detail,index) in fabric.weft">
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="detail" v-model="detail.yarn.count.code" >
                      <option v-for="count in counts" v-bind:value="count.code">
                        {{ count.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="detail" v-model="detail.yarn.design.code" >
                      <option value="1">-</option>
                      <option v-for="design in designs" v-bind:value="design.code">
                        {{ design.name }}
                      </option>
                    </select>
                  </td>
                <td style="padding: 0px;">
                  <select class="form-control" v-if="detail" v-model="detail.yarn.color.code" >
                    <option value="1">-</option>
                    <option v-for="color in colors" v-bind:value="color.code">
                      {{ color.name }}
                    </option>
                  </select>
                </td>
                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" placeholder="Weight in Kgs" min="0"  v-if="detail" v-model="detail.weight" >
                </td>
                <td style="padding: 0px;text-align: center;width: 30px;">
                  <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeWeftRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                </td>
              </tr>
              </tbody>
              <tfoot>
                <td style="padding: 0px;">
                  <button type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addWeftRow">
                    <i class="icon-plus3"></i>
                  </button>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer" style="padding-bottom: 3px;padding-top: 3px;">
      <div class="text-right" >
        <button type="button" class="btn btn-outline-success" @click="saveDocument" >Update<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'YarnRequirementForm',
    data () {
      return {
        fabric: JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}'),
        counts: [],
        designs: [],
        colors:[],
        warp: JSON.parse('{"yarn":{"id":"","code":0,"status":0,"type":0,"process":{"id":"","code":0,"name":"","nature":0,"sname":"","sys_obj":false},"count":{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0},"name":"","design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","print_name":""},"pantone":"","print_name":"","weight":0},"qty_txt":"","qty":0,"weight":0}')
      }
    },
    props: {
      myfabric: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}')
      }
    },
    beforeMount () {
      //if(this.myfabric.code > 1){
        this.fabric = this.myfabric; // save props data to itself's data and deal with tms
      //}
    },
    component: {

    },
    created () {
      //this.$data.fabric = JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}')
    },
    mounted () {
      let self = this;
      $('.form-control-select2').select2()

      self.loadCount();
      self.loadColors();
      self.loadDesigns();
    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeYarnReqModal();
      },
      clear(){
        this.$data.fabric = JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}');
      },
      addWarpRow(){
        this.$data.fabric.warp.push (JSON.parse('{"yarn":{"id":"","code":0,"status":0,"type":0,"process":{"id":"","code":0,"name":"","nature":0,"sname":"","sys_obj":false},"count":{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0},"name":"","design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","print_name":""},"pantone":"","print_name":"","weight":0},"qty_txt":"","qty":0,"weight":0}'));
      },
      removeWarpRow(idx){
        if (idx > -1) {
          this.$data.fabric.warp.splice(idx, 1);
        }
      },
      addWeftRow(){
        this.$data.fabric.weft.push (JSON.parse('{"yarn":{"id":"","code":0,"status":0,"type":0,"process":{"id":"","code":0,"name":"","nature":0,"sname":"","sys_obj":false},"count":{"id":"","code":0,"type":0,"status":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"weight":0},"name":"","design":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","print_name":""},"pantone":"","print_name":"","weight":0},"qty_txt":"","qty":0,"weight":0}'));
      },
      removeWeftRow(idx){
        if (idx > -1) {
          this.$data.fabric.weft.splice(idx, 1);
        }
      },
      loadAll(){
        this.loadCount();
        this.loadColors();
        this.loadDesigns();
      },
      loadCount(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/count/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.counts = data.data;

          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadColors(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/color/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.colors = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadDesigns(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/design/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.designs = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      saveDocument(){
        let self = this;


        if(self.$data.fabric.code < 1){
          alert("Invalid Fabric");
          return
        }


        self.fabric.warp.forEach(function (warp) {
          warp.yarn.design.code = parseInt(warp.yarn.design.code);
          warp.yarn.color.code = parseInt(warp.yarn.color.code);
          warp.weight =  parseFloat(warp.weight);
        });


        self.fabric.weft.forEach(function (weft) {
          weft.yarn.design.code = parseInt(weft.yarn.design.code);
          weft.yarn.color.code = parseInt(weft.yarn.color.code);
          weft.weight =  parseFloat(weft.weight);
        });

        const requestOptions = {
          method:  'PUT',
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.fabric)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/fabric/yarnrequirement/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.fabric = JSON.parse('{"id":"","code":0,"status":0,"type":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":1,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"conv_fact":1,"sub_unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false}},"design":{"id":"","code":1,"name":"","p_name":""},"style":{"id":"","code":1,"name":"","p_name":""},"color":{"id":"","code":1,"status":0,"name":"","print_name":""},"quality":{"id":"","code":1,"name":"","p_name":""},"size":{"id":"","code":1,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]}');
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
